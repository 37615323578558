import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { QuestionIdParamKey } from '@pixels/client/pages/my-question/my-question.model';
import {
  AppKeyAgain,
  AppKeyAiChat,
  AppKeyChatKorean,
  AppKeyChristian,
  AppKeyImsolo,
  AppKeyInstaChat,
  AppKeyKdramaChat,
  AppKeyKpop,
  AppKeyMbti,
  AppKeySingles,
  AppKeySoulmate
} from '@pixels/universal/model/apps/app-types';
import {
  BoardPostParamKey,
  generateBoardPostPath,
  generateBoardPostsByCategory
} from '@pixels/universal/model/board/board-post/board-post.model';
import { HChatParamKey } from '@pixels/universal/model/chat/chat-constants';
import { generateChatRoomPath, IChatParamKey } from '@pixels/universal/model/insta-chat/insta-chat-constant';
import { InstaChatUserForUserInfo } from '@pixels/universal/model/insta-chat/insta-chat-user/insta-chat-user.schema';
import { KOREA_COUNTRY_CODE } from '@pixels/universal/model/nation/nation.model';
import { isEmpty } from 'lodash-es';

export enum IChatQueryParamKey {
  fromUrl = 'fromUrl'
}

export const PATH_MAP = {
  login: {
    name: _('@로그인@'),
    path: 'login',
    fullPath: '/login',
  },
  smsCode: {
    name: 'sms-code',
    path: 'sms-code',
    fullPath: '/sms-code',
  },
  mySetting: {
    name: _('@설정@'),
    path: 'my-settings',
    fullPath: '/my-settings',
  },
  usernameSetting: {
    name: _('@사용자_이름_설정@'),
    path: 'username-setting',
    fullPath: '/username-setting',
  },
  chatRoom: {
    name: '채팅방',
    path: `chat-rooms/:${HChatParamKey.chatRoomId}`,
    fullPath: generateChatRoomPath,
  },
  chatting: {
    name: _('@내_채팅@'),
    path: 'chatting',
    fullPath: '/chatting',
  },
  apply: {
    name: _('@신청@'),
    path: 'apply',
    fullPath: '/apply',
  },
  boardPost: {
    name: _('@게시글@'),
    path: `posts/:${BoardPostParamKey.postId}`,
    fullPath: generateBoardPostPath,
  },
  boardPosts: {
    name: _('@게시판@'),
    path: 'posts',
    fullPath: '/posts',
  },
  boardPostsByCategory: {
    name: _('@주제_게시판@'),
    path: `posts/categories/:${BoardPostParamKey.categoryId}`,
    fullPath: generateBoardPostsByCategory,
  },
  chatRooms: {
    name: _('@채팅방@'),
    path: 'chat-rooms',
    fullPath: '/chat-rooms',
  },
  waitingRoom: {
    name: _('@대기실@'),
    path: 'waiting-room',
    fullPath: '/waiting-room',
  },
  myProfile: {
    name: _('@프로필@'),
    path: 'my-profile',
    fullPath: '/my-profile',
  },
  myProfileEdit: {
    name: '프로필 수정',
    path: 'my-profile-edit',
    fullPath: '/my-profile-edit',
  },
  linkPhone: {
    name: 'link-phone',
    path: 'link-phone',
    fullPath: '/link-phone',
  },
  contents: {
    name: 'contents',
    path: 'contents',
    fullPath: '/contents',
  },
  imsolo: {
    name: 'imsolo-contents',
    path: 'contents',
    fullPath: '/contents',
  },
  kpop: {
    name: 'kpop-contents',
    path: 'contents',
    fullPath: '/contents',
  },
  checkRedirect: {
    name: 'checkRedirect',
    path: 'check-redirect',
    fullPath: '/check-redirect',
  },
  otherProfile: {
    name: '프로필 보기',
    path: `profile/:${IChatParamKey.userId}`,
    fullPath: generateProfile,
  },
  postNotification: {
    name: _('@게시글_알림@'),
    path: 'post-notification',
    fullPath: '/post-notification'
  },
  recommendedFriendsNotification: {
    name: _('@추천친구_알림@'),
    path: 'recommended-friends-notification',
    fullPath: '/recommended-friends-notification'
  },
  myQuestion: {
    name: '문의하기',
    path: 'my-questions',
    fullPath: '/my-questions'
  },
  myQuestionView: {
    name: '문의내용',
    path: `my-questions/:${QuestionIdParamKey}`,
    fullPath: generateQuestions,
  },
  unregister: {
    name: '탈퇴 요청',
    path: 'unregister',
    fullPath: '/unregister'
  },
  unregisterComplete: {
    name: '탈퇴 완료',
    path: 'unregister-complete',
    fullPath: '/unregister-complete'
  },
  punishment: {
    name: '징계',
    path: 'punishment',
    fullPath: '/punishment'
  },
  appInstall: {
    name: 'app-install',
    path: 'app-install',
    fullPath: '/app-install'
  },
  underMaintenance: {
    name: '점검중',
    path: 'under-maintenance',
    fullPath: '/under-maintenance'
  },
  versionUpWaiting: {
    name: '버전업대기중',
    path: 'version-up-waiting',
    fullPath: '/version-up-waiting'
  },
  static: {
    name: '스태틱뷰어',
    path: `static/:${IChatParamKey.contentName}`,
    fullPath: generateStaticViewerPath,
  },
  bookmarklet: {
    name: '북마클릿',
    path: 'bookmarklet',
    fullPath: '/bookmarklet',
  },

  test: {
    name: 'test',
    path: 'test',
    fullPath: '/test'
  },
  videoChat: {
    name: '영상채팅',
    path: 'video-chat',
    fullPath: '/video-chat'
  },
  uploadTest: {
    name: '업로드테스트',
    path: 'upload-test',
    fullPath: '/upload-test'
  },
  photoViewerTest: {
    name: '포토뷰어테스트',
    path: 'photo-viewer',
    fullPath: '/photo-viewer'
  },
  youtubeTest: {
    name: '유투브테스트',
    path: 'youtube',
    fullPath: '/youtube'
  },
  keyboardTest: {
    name: '키보드테스트',
    path: 'keyboard-test',
    fullPath: '/keyboard-test'
  },
};

export function generateProfile(instaChatUserId: string, fromUrl = ''): string {
  const paths = [`/profile/${instaChatUserId}`];

  if (fromUrl) {
    paths.push(`?${IChatQueryParamKey.fromUrl}=${fromUrl}`);
  }

  return paths.join('');
}

export function generateStaticViewerPath(contentName: string): string {
  return `/static/${contentName}`;
}

export function generateQuestions(questionId: string): string {
  return `/my-questions/${questionId}`;
}

export function isEmptyForInstaChatUserByOneOnOneType(user: InstaChatUserForUserInfo, appType: string): boolean {
  switch (appType) {
    case AppKeyMbti:
      return isEmpty(user.mbti);
    case AppKeyKpop:
      return isEmpty(user.artist);
    case AppKeyAgain:
    case AppKeySingles:
    case AppKeyChristian:
    case AppKeyInstaChat:
    case AppKeySoulmate:
      return (!user.countryCode || user.countryCode === KOREA_COUNTRY_CODE) && isEmpty(user.location);
    case AppKeyImsolo:
    case AppKeyChatKorean:
    case AppKeyKdramaChat:
    case AppKeyAiChat:
    default:
      return false;
  }
}

export function isAdminPath(fullPath: string): boolean {
  return /^\/admin\/.*/.test(fullPath);
}
